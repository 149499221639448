import { nanoid } from "nanoid";
import { defineStore } from "pinia";

import type { Color } from "@solvari/common-fe";

type AlertData = {
  autohide: boolean;
  button: null;
  callback: null;
  dismissible: boolean;
  id: string;
  message: string | null;
  timestamp: Date;
  title: string | null;
  type: Color | "dark" | "info" | "light" | "secondary";
};

export const useAlertStore = defineStore("alerts", {
  state: () => ({
    alerts: [] as AlertData[],
  }),
  getters: {},
  actions: {
    add(alert: Partial<AlertData> | string) {
      let alertData: AlertData = {
        id: nanoid(10),
        title: null,
        message: null,
        button: null,
        callback: null,
        dismissible: true,
        type: "warning",
        autohide: true,
        timestamp: new Date(),
      };

      if (typeof alert === "string") {
        alertData.message = alert;
      }

      if (typeof alert === "object") {
        alertData = Object.assign(alertData, alert);
        switch (alertData.type) {
          case "primary":
          case "secondary":
          case "success":
          case "danger":
          case "warning":
          case "info":
          case "light":
          case "dark":
            break;
          default:
            alertData.type = "warning";
            break;
        }
      }

      this.alerts.push(alertData);

      if (alertData.autohide) {
        setTimeout(() => {
          this.remove(alertData.id);
        }, 5000);
      }
      return alertData.id;
    },
    remove(alertId: string) {
      if (
        this.alerts.filter((alert) => {
          return alert.id === alertId;
        }).length === 0
      ) {
        return false;
      }
      this.alerts = this.alerts.filter((alert) => alert.id !== alertId);
      return true;
    },
  },
});
